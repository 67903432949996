import type { FacetValueFilterInput, Scalars } from '#graphql-operations'
import type { Ref } from 'vue'

export function getFacetValueIds(): string[] {
  const route = useRoute()
  return (route.query.fvid as string)?.split(',') || []
}

export function getFacetedSellerId() {
  const route = useRoute()
  return route.name?.toString().indexOf('stores-id') !== -1 ? route.params.id.toString() : undefined
}

export function useProductsGridQueryParams() {
  const route = useRoute()
  return {
    term: computed(() => route.query.q?.toString()),
  }
}

function useSearchFacetValueFilters(): { facetValueFilters: Ref<FacetValueFilterInput[]> } {
  return {
    facetValueFilters: computed(() => {
      const facetValueIds = getFacetValueIds()
      const facetedSellerId = getFacetedSellerId()
      const ids = [...facetValueIds, facetedSellerId].filter(Boolean)

      if (ids.length)
        return [{ or: ids as string[] }]

      return []
    }),
  }
}

function useSearchByCollectionSlug() {
  const route = useRoute()

  return {
    collectionSlug: computed(() => {
      return route.name?.toString().indexOf('collections-slug') !== -1
        ? route.params.slug.at(-1) as Scalars['ID']['input']
        : undefined
    }),
  }
}

export function useSearchParams() {
  const { term } = useProductsGridQueryParams()
  const { facetValueFilters } = useSearchFacetValueFilters()
  const { collectionSlug } = useSearchByCollectionSlug()

  return {
    searchParams: computed(() => ({
      term: term.value,
      facetValueFilters: facetValueFilters.value,
      collectionSlug: collectionSlug.value,
    })),
  }
}
